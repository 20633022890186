.sort-buttons-container form {
    display: flex;
    /* flex-wrap: wrap;
    flex-direction: column; */
  }
  
  .sort-buttons-container label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
  }
  
  .sort-buttons-container  label input {
    position: absolute;
    left: -9999px;
  }
  
  .sort-buttons-container label input:checked + span {
    background-color: #414181;
    color: white;
  }
  
  .sort-buttons-container label input:checked + span:before {
    box-shadow: inset 0 0 0 0.4375em #00005c;
  }
  
  .sort-buttons-container label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    color: var(--text-color);
  }
  
  .sort-buttons-container label span:hover {
    background-color: #d6d6e5;
    color: #414181  ;
  }
  
  .sort-buttons-container label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #00005c;
  }
  