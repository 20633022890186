.like-status-dialog-message {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 20px 0;
    padding: 0 20px;
    background-color: var(--text-color);
    border-radius: 10px;
    z-index: 999999999;
}

.like-status-dialog-message p {
    font-weight: bold;
    font-size: 20px;
}

.like-status-dialog-message p.fail {
    color: var(--error-color);
}

.like-status-dialog-message p.success {
    color: var(--success-color);
}


