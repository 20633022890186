.upcoming-nft-container {
    background-color: #0C0F1D;
    padding: 50px;
    font-family: Arial, sans-serif;
    color: #fff;
  }
  
  .upcoming-nft-container h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  
  .upcoming-nfts {
    display: flex;
    flex-wrap: wrap;
  }
  
  .upcoming-nft {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-bottom: 30px;
    width: 250px;
  }
  
  .nft-image img {
    width: 100%;
  }
  
  .nft-details {
    background-color: #1B1E2D;
    padding: 20px;
    border-radius: 10px;
    margin-top: -10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .nft-details h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .nft-details p {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .nft-details p.chain-label,
  .nft-details p.price-label,
  .nft-details p.supply-label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .nft-details p.chain-value,
  .nft-details p.price-value,
  .nft-details p.supply-value {
    margin-bottom: 5px;
  }
  
  .nft-links {
    margin-top: 10px;
  }
  
  .nft-links a {
    color: #0077C2; /* Medium blue color */
    text-decoration: none;
    margin-right: 10px;
  }
  
  .nft-links a:hover {
    text-decoration: underline;
  }
  
  .upcoming-container-top {
    text-align: center;
    margin: 0 auto;
    max-width: 800px; /* adjust as needed */
    font-size: 85%;
  }

  .upcoming-text.submit a {
    color: red;
  }
  