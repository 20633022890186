  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 1rem;
    font-size: 0.9rem;
  }

  .footer-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .footer-text {
    margin-right: 1rem;
  }

  .footer-icon {
    color: blue;
    font-size: 1.2em;
    margin-right: 10px;
  }
  

  .footer-icons a {
    color: var(--text-color);
    margin-right: 1rem;
    transition: transform 0.3s ease-in-out;
  }

  .footer-icons a:hover {
    transform: scale(1.2);
  }

  .footer-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    .footer {
      flex-direction: column;
      text-align: center;
    }

    .footer-left {
      margin-bottom: 1rem;
    }
  }