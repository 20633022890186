.input {
    color: var(--text-color-dark);
    background: #414181;
    border-radius: 50px;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: bold;
    outline: none;
}

.input::placeholder {
    color: var(--text-color-dark);
    opacity: 0.8;
}

.input:focus::placeholder {
    opacity: 0;
}

.input-animation {
    animation: input-pulse 1.5s ease-in-out infinite;
}

@keyframes input-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
