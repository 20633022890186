header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 80px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.logo {
  font-size: 28px;
  font-weight: bold;
  color: var(--text-color);
}


header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #0c0c0c; */
  padding: 1rem;
  z-index: 10;
}

header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

header nav ul li {
  margin: 0 1rem;
}

header nav ul li a.nav-link {
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.2rem;
  font-weight: bold;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: var(--text-color);
  margin: 5px;
  transition: all 0.3s ease;
}

li.menu-search-bar {
  display: none;
}

@media screen and (max-width: 768px) {

  .logo {
    font-size: 24px;
  }

  .search-bar-wrapper {
    display: none;
  }

  .nav-links {
    position: absolute;
    right: 0;
    top: 5rem;
    background-color: var(--background-color);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  }

  .nav-active {
    transform: translateX(0%);
  }

  .burger {
    display: block;
  }

  li.menu-search-bar {
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 99999999;
  }
}