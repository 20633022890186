.collection-card {
    border: 1px solid var(--border-color);
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.collection-card .image-wrapper {
    position: relative;
}

.collection-card span {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    background-color: var(--background-color);
}


.collection-card .image-wrapper img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.collection-card .name-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.collection-card h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

.collection-card p {
    margin-bottom: 20px;
    font-size: 16px;
}