.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--text-color);
    opacity: 0.5;
    z-index: 9999;
}

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 10px;
    border-radius: 20px;
    z-index: 99999;
    display: flex;
    width: 575px; /* Reduce width by 5% */
    height: 575px; /* Reduce height by 5% */
}

.dialog-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
}

.dialog-button:hover {
  background-color: #0069d9;
  color: #fff;
}

.dialog-button:active {
  background-color: #005cbf;
  color: #fff;
}



.dialog .close-icon {
    position: absolute;
    right: 30px;
    cursor: pointer;
    z-index: 999999;
}

.dialog-section {
    flex: 1;
    margin-right: 20px;
    padding: 10px;
}

.dialog-section h2 {
    color: var(--primary-color);
}

.dialog-section img {
    max-width: 100%;
    height: auto;
}

.dialog-section:last-child {
    margin-right: 0;
    /* For firefox */
    scrollbar-color: var(--text-color) var(--background-color);
    scrollbar-width: thin;
    
}

@media (max-width: 768px) {
    
    .dialog {
        flex-direction: column;
        position: fixed;
        bottom: 0;
        margin: 0;
        padding: 1rem;
        overflow-y: auto;
        height: 85vh;
        width: 60%;
      }
    
    .dialog-section {
        max-width: none;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) {
    .dialog-section:nth-of-type(2) {
        max-height: 80vh;
        overflow-y: scroll;
    }
}

.progress-bar {
    position: relative;
    height: 16px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #007bff;
  }
  
  .progress-bar-score {
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    line-height: 16px;
    padding: 0 8px;
    font-size: 12px;
    color: #333;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  

.progress-bar-label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color);
    font-weight: bold;
} 
.progress-bar-trait-count {
    position: absolute;
    left: 100%;
    top: -10px;
    font-size: 12px;
    font-weight: bold;
  }




::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--background-color); /* Color of the track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--text-color); /* Color of the handle */
    border-radius: 5px; /* Rounded corners */
  }
  
  
