:root {
  --background-color-dark: #0C0F1D;
  --background-color-light: #ECECEC;
  --primary-color: #1DA1F2;
  --secondary-color: #FF9800;
  --text-color-dark: #F8F8F8;
  --text-color-light: #333333;
  --link-color: #00C853;
  --error-color: #F44336;
  --success-color: #4CAF50;
  --border-color-light: #ccc;
  --box-shadow-light: 0 0 5px rgba(0, 0, 0, 0.2);
  --border-color-dark: #555;
  --box-shadow-dark: 0 0 5px rgba(255, 255, 255, 0.2);

}

.light {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --border-color: var(--border-color-light);
  --box-shadow: var(--box-shadow-light);
}

.dark {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --border-color: var(--border-color-dark);
  --box-shadow: var(--box-shadow-dark);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
